import React from 'react';
import { IconButton, Box } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const ChatBot = ({ onClose }) => {
  return (
    <Box
      position='fixed'
      right={{ base: '0', md: '90px' }}
      bottom={{ base: 'auto', md: '30px' }}
      top={{ base: '0', md: 'auto' }}
      width={{ base: '100%', md: '350px' }}
      minW={{ base: '100%', md: '350px' }}
      height={{ base: 'calc(100vh - 60px)', md: '550px' }}
      borderRadius={{ base: '0', md: '20px' }}
      zIndex='1000'
      bg='white'
      boxShadow='lg'
      className='chatbot'
    >
      <IconButton
        aria-label='Close Chatbot'
        icon={<CloseIcon />}
        position='absolute'
        top='22px'
        right='26px'
        borderRadius='full'
        height='30px'
        bg='white'
        color='black'
        zIndex='1002'
        variant='ghost'
        onClick={onClose}
      />
      <iframe
        src='https://chatbot.simplified.com/iframe/239db551-1a53-43f4-9db0-eedeb4cb881f'
        allowFullScreen
        title='ChatBot'
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '20px',
        }}
      />
    </Box>
  );
};

export default ChatBot;
