import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import styles from './filters.module.scss';
import { useQuery } from '../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../redux/homeSlice';
import {
  setFilters,
  setMobileFilters,
  setProducts,
} from '../../redux/productSlice';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'is-empty';
import CheckboxComponent from './shipping-checkbox';
import { useDecryptParam } from '../../hooks/useDecryptParam';
import { usehashParam } from '../../hooks/useHashParam';

const Filters = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const toast = useToast();
  let [searchParams, setSearchParams] = useSearchParams();

  const is_free_shipping = useDecryptParam(query.get('is_free_shipping'));
  const min_discount = useDecryptParam(query.get('min_discount'));
  const rating = useDecryptParam(query.get('rating'));
  const min_price = useDecryptParam(query.get('min_price'));
  const max_price = useDecryptParam(query.get('max_price'));
  const is_filter_search = query.get('is_filter_search');
  const is_rating_search = query.get('is_rating_search');
  const is_free_search = query.get('is_free_search');
  const is_sale_search = query.get('is_sale_search');
  const banner = query.get('banner');
  const is_mobile_filter_search = query.get('is_mobile_filter_search');

  const { filters, mobileFilters } = useSelector((state) => state.product);
  const [min, setMin] = useState(min_price || '');
  const [max, setMax] = useState(max_price || '');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [isFreeShipping, setIsFreeShipping] = useState(false);

  const [sale, setSale] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [isSale, setIsSale] = useState(false);
  const [ratingFlag, setRatingFlag] = useState(false);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  useEffect(() => {
    if (!isSmallerThan767) {
      if (is_free_shipping || rating) {
        dispatch(
          setFilters({
            isFreeShipping: is_free_shipping === '1',
            rating,
          })
        );
      }
    }
  }, [dispatch, isSmallerThan767, is_free_shipping, rating]);

  // Function to update filters and dispatch
  const updateFilters = (updatedFields) => {
    dispatch(setPage(1));
    dispatch(setFilters(updatedFields));
  };

  const queryParams = {};
  for (const entry of searchParams.entries()) {
    // make object key value pair

    queryParams[entry[0]] = entry[1];
  }

  const handlePriceValidationAndUpdate = () => {
    const minPrice = Number(min || 0);
    const maxPrice = Number(max || minPrice);

    if (!min) {
      toast({
        title: 'Error',
        description: 'Please enter min price',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!max) {
      toast({
        title: 'Error',
        description: 'Please enter max price',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (minPrice > maxPrice) {
      toast({
        title: 'Error',
        description: 'Min price should be less than max price',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    searchParams.set('min_price', usehashParam(minPrice));
    searchParams.set('max_price', usehashParam(maxPrice));
    searchParams.set('is_filter_search', true);
    setSearchParams(searchParams);

    updateFilters({ min: minPrice, max: maxPrice });
    setMin(minPrice);
    setMax(maxPrice);
  };

  useEffect(() => {
    if (banner) dispatch(setFilters({ banner }));
  }, [banner, dispatch]);

  useEffect(() => {
    if (min_discount) {
      setSale(!isEmpty(min_discount));
    }
    setSale(filters?.sale);
  }, [min_discount, filters?.sale]);

  useEffect(() => {
    if (rating) {
      setIsRating(true);
    }
  }, [rating]);
  useEffect(() => {
    if (is_filter_search && isEmpty(is_mobile_filter_search)) {
      setMinPrice(min_price);
      setMaxPrice(max_price);
    }
  }, [is_filter_search, is_mobile_filter_search]);
  useEffect(() => {
    if (is_rating_search === 'true') {
      setRatingFlag(!isEmpty(is_rating_search));
    } else if (is_rating_search === 'false') {
      setRatingFlag(false);
    }
  }, [is_rating_search]);
  useEffect(() => {
    if (is_sale_search === 'true') {
      setIsSale(true);
    } else if (is_sale_search === 'false') {
      setIsSale(false);
    }
  }, [is_sale_search]);
  useEffect(() => {
    if (is_free_search === 'true') {
      setIsFreeShipping(true);
    } else if (is_free_search === 'false') {
      setIsFreeShipping(false);
    }
  }, [is_free_search]);

  useEffect(() => {
    setMin(min_price || '');
    setMax(max_price || '');
  }, [min_price, max_price]);
  useEffect(() => {
    if (isEmpty(filters?.min) && isEmpty(filters?.max)) {
      setMin(filters?.min);
      setMax(filters?.max);
    }
  }, [filters?.min, filters?.max]);

  useEffect(() => {
    setMin(min_price || '0');
    setMax(max_price || min_price || '');
  }, [min_price, max_price]);

  return (
    <>
      <Accordion
        className={styles.accordion}
        defaultIndex={[0, 1, 2, 3]}
        allowMultiple
      >
        <AccordionItem className={styles.item}>
          <h2>
            <AccordionButton className={styles.button}>
              <Box as='span' flex='1' textAlign='left'>
                Delivery options & services
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} className={styles.panel}>
            <CheckboxComponent
              filters={filters}
              setSearchParams={setSearchParams}
              queryParams={queryParams}
              setIsFreeShipping={setIsFreeShipping}
              isFreeShipping={isFreeShipping}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className={styles.item}>
          <h2>
            <AccordionButton className={styles.button}>
              <Box as='span' flex='1' textAlign='left'>
                Discounts
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} className={styles.panel}>
            <Checkbox
              isChecked={isSale || mobileFilters.sale}
              onChange={(e) => {
                setSale(!sale);
                setIsSale(e.target.checked);
                if (isSmallerThan767) {
                  dispatch(setMobileFilters({ sale: e.target.checked }));
                  return;
                }
                dispatch(
                  setFilters({
                    sale: e.target.checked,
                  })
                );
                if (e.target.checked) {
                  setSearchParams({
                    ...queryParams,
                    is_sale_search: true,
                  });
                } else {
                  delete queryParams.min_discount;
                  delete queryParams.max_discount;
                  setSearchParams({
                    ...queryParams,
                  });
                }
              }}
            >
              Sale
            </Checkbox>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className={styles.item}>
          <h2>
            <AccordionButton className={styles.button}>
              <Box as='span' flex='1' textAlign='left'>
                Price
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} className={styles.panel}>
            <Flex align={'center'} gap={'5px'}>
              <Input
                onChange={(e) => {
                  setMin(e.target.value);
                  setMinPrice(e.target.value);
                }}
                value={minPrice}
                width={'45%'}
                type='text'
                placeholder='Min'
              />
              <Box flex={1}>-</Box>
              <Input
                onChange={(e) => {
                  if (max_price) {
                    //should not allow more than max_price
                    if (Number(e.target.value) > Number(max_price)) {
                      return;
                    }
                  }
                  setMax(e.target.value);
                  setMaxPrice(e.target.value);
                }}
                value={maxPrice}
                width={'45%'}
                type='text'
                placeholder='Max'
              />
              {!isSmallerThan767 && (
                <Button
                  onClick={handlePriceValidationAndUpdate}
                  width={'30px'}
                  variant={'primary'}
                >
                  OK
                </Button>
              )}
            </Flex>
            {!isSmallerThan767 && (
              <Flex justify={'flex-end'}>
                <Text
                  textTransform={'uppercase'}
                  color={'#F41106'}
                  cursor={'pointer'}
                  fontSize={'14px'}
                  // onClick={() => {
                  //   setMin('');
                  //   setMax('');
                  //   updateFilters({ min: null, max: null });
                  // }}
                  onClick={() => {
                    setMin('');
                    setMax('');
                    setMinPrice('');
                    setMaxPrice('');
                    updateFilters({ min: null, max: null });
                    searchParams.delete('min_price');
                    searchParams.delete('max_price');
                    searchParams.delete('is_filter_search');
                    setSearchParams(searchParams);
                  }}
                >
                  Reset
                </Text>
              </Flex>
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem className={styles.item}>
          <h2>
            <AccordionButton className={styles.button}>
              <Box as='span' flex='1' textAlign='left'>
                Rating
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} className={styles.panel}>
            <Checkbox
              isChecked={ratingFlag}
              onChange={(e) => {
                setIsRating(e.target.checked);
                setRatingFlag(e.target.checked);
                if (isSmallerThan767) {
                  dispatch(
                    setMobileFilters({ rating: e.target.checked ? 5 : null })
                  );
                  return;
                }
                if (e.target.checked) {
                  updateFilters({ rating: 5 });
                  setSearchParams({
                    ...queryParams,
                    rating: usehashParam(5),
                    is_rating_search: true,
                  });
                } else {
                  delete queryParams.rating;
                  delete queryParams.is_rating_search;
                  setSearchParams({
                    ...queryParams,
                  });

                  updateFilters({ rating: null });
                }
              }}
            >
              High Rating
            </Checkbox>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {isSmallerThan767 && (
        <Button
          variant={'primary'}
          onClick={() => {
            setSearchParams({
              ...queryParams,
              is_free_search: mobileFilters.isFreeShipping ? true : false,
              is_free_shipping: usehashParam(
                mobileFilters.isFreeShipping ? 1 : 0
              ),
              is_sale_search: mobileFilters.sale ? true : false,
              is_rating_search: mobileFilters.rating ? true : false,
              is_mobile_filter_search: true,
            });
            dispatch(
              setFilters({
                isFreeShipping: mobileFilters.isFreeShipping,
                sale: mobileFilters.sale,
                rating: mobileFilters.rating,
              })
            );
            setSale(mobileFilters.sale);
            setIsSale(mobileFilters.sale);
            if (minPrice && maxPrice) {
              updateFilters({ min: minPrice, max: maxPrice });
              setMin(minPrice);
              setMax(maxPrice);
            } else {
              updateFilters({ min: null, max: null });
              setMinPrice('');
              setMaxPrice('');
            }
            dispatch(setProducts([]));
          }}
        >
          Submit
        </Button>
      )}
    </>
  );
};

export default Filters;
