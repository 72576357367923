import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
  Flex,
  Radio,
  RadioGroup,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedShipment, setShipment } from '../../../redux/productSlice';
import isEmpty from 'is-empty';
import { updateCart } from '../../../services/cart-service';
import { updateCartHandler } from '../../../redux/cartSlice';

const ShippingModal = ({ isOpen, onClose, fromCart, product }) => {
  const dispatch = useDispatch();
  const [selectedMethod, setSelectedMethod] = useState(null);
  const { country, selectedCountry } = useSelector((state) => state.home);
  const { productDetails, shipment, isLoading, selectedShipment } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    if (!isEmpty(productDetails)) {
      dispatch(setShipment(productDetails.shipment));
    }
  }, [dispatch, productDetails]);

  useEffect(() => {
    if (selectedShipment) {
      setSelectedMethod(selectedShipment.logistic_service);
      if (product?.id) {
        const payload = {
          id: product?.id,
          shipment_fee: selectedShipment.shipment_fee,
        };
        dispatch(updateCartHandler(payload));
      }
    }
  }, [selectedShipment, product]);

  useEffect(() => {
    if (selectedMethod && !isEmpty(shipment) && shipment.length > 0) {
      dispatch(
        setSelectedShipment(
          shipment?.find(
            (method) => method.logistic_service === selectedMethod
          ) || shipment[0]
        )
      );
    }
  }, [dispatch, selectedMethod, shipment]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='2xl' minHeight='600px'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Shipping Method</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex justifyContent='center' alignItems='center' height='200px'>
              <Spinner size='md' />
            </Flex>
          ) : (
            <RadioGroup
              borderRadius='8px'
              onChange={setSelectedMethod}
              value={selectedMethod}
            >
              <Stack spacing={4}>
                {shipment?.map((method) => (
                  <Box
                    key={method.id}
                    p={3}
                    borderWidth='1px'
                    bg='#f9f9f9'
                    cursor='pointer'
                    borderRadius='8px'
                  >
                    <Box>
                      <Flex justifyContent={'space-between'}>
                        <Text fontWeight='600'>
                          Shipping:{' '}
                          {method.free_shipping === '1' ? (
                            'Free Shipping'
                          ) : (
                            <Text as='span'>{method.shipment_fee}</Text>
                          )}
                        </Text>
                        <Radio value={method.logistic_service} />
                      </Flex>
                      <Text mt={1} fontSize='14px' color='gray.500'>
                        Delivery{' '}
                        <Text as='span' color='black' fontWeight='500'>
                          {method.delivery_date}
                        </Text>
                      </Text>
                      <Text as='span' color='black' fontWeight='500'>
                        via {method.company}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShippingModal;
