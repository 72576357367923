import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ProductSlider from '../../slider';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../deals/deals.module.scss';
import ArrowRight from '../../icons/arrow-right';
import { useLocation, useNavigate } from 'react-router-dom';
import { usehashParam } from '../../../hooks/useHashParam';
import { setStore } from '../../../redux/homeSlice';
import { setProducts } from '../../../redux/productSlice';

const RecommendationFromSameSeller = ({ title, onCartOpen, storeName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    homeData: { best_deal_products, best_deal_attributes },
  } = useSelector((state) => state.home);
  const { filters, products, isListingLoading, totalCount } = useSelector(
    (state) => state.product
  );

  const productCount = useSelector((state) => state?.product?.totalCount);
  const productFromSameSeller = useSelector(
    (state) => state.product.productFromSameSeller
  );
  const urlParams = new URLSearchParams(location.search);
  const productId = location.pathname.split('/')[3];
  const skuId = urlParams.get('sku_id');

  return (
    <>
      {products.length > 0 && (
        <Box
          className={styles.best_deals}
          bg='#FFF'
          mt='50px'
          // padding={'20px 29px'}
        >
          <Flex justify={'space-between'} align={'center'} mb='30px'>
            <Text color={'#000'} fontSize={'16px'} fontWeight={500}>
              {title}
            </Text>
            <Flex align={'center'} gap={'5px'} cursor={'pointer'}>
              <Text
                lineHeight={'18px'}
                fontWeight={700}
                fontSize={'16px'}
                color={'#222'}
                onClick={() => {
                  dispatch(setStore(storeName));
                  dispatch(setProducts([]));

                  navigate(
                    `/product/listing?productId=${productId}&store_name=${storeName}&is_store=1&product_count=${productCount}`
                  ); // Absolute path
                }}
              >
                View All
              </Text>
              <ArrowRight />
            </Flex>
          </Flex>

          <Divider mb='35px' mt='-10px' />
          <ProductSlider
            items={products}
            isBestDeals={false}
            onCartOpen={onCartOpen}
          />
        </Box>
      )}
    </>
  );
};

export default RecommendationFromSameSeller;
