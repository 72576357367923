import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  endActivityHandler,
  startActivityHandler,
} from '../../redux/homeSlice';
import { useMediaQuery } from '@chakra-ui/react';
import { logoutHandler } from '../../redux/authSlice';

let count = 0;
let count15 = 0;

const InactivityLogoutTimer = () => {
  const dispatch = useDispatch();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  const checkForInactivity = () => {
    const expireTimeString = localStorage.getItem('expireTime');
    const expireTimeString15 = localStorage.getItem('expireTime15');
    const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;
    const expireTime15 = expireTimeString15 ? parseInt(expireTimeString15) : 0;

    if (expireTime15 && expireTime15 < Date.now()) {
      if (count15 > 0) return;
      dispatch(endActivityHandler());
      count = 0;
      count15++;
      localStorage.removeItem('expireTime15');
    }

    if (window.innerWidth > 600) {
      if (expireTime && expireTime < Date.now()) {
        dispatch(logoutHandler()).then((res) => {
          if (res.status === 200) {
            dispatch(endActivityHandler());
            localStorage.clear();
            localStorage.setItem('isAuthenticated', 'true');
            window.location.href = '/';
          }
        });
      }
    }
  };

  const updateExpiryTime = () => {
    const expireTime = Date.now() + 60 * 60 * 1000; // 1 hours in milliseconds
    const min15Time = Date.now() + 15 * 60 * 1000;
    localStorage.setItem('expireTime', expireTime.toString());
    localStorage.setItem('expireTime15', min15Time.toString());
    count15 = 0;
    if (count > 0) return;
    dispatch(startActivityHandler());
    count++;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000); // Check for inactivity every 5 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const events = ['click', 'keypress', 'scroll', 'mousemove'];

    const update = () => {
      updateExpiryTime();
    };

    events.forEach((event) => window.addEventListener(event, update));

    return () => {
      events.forEach((event) => window.removeEventListener(event, update));
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Custom logic before closing or refreshing
      console.log('Browser is about to close or reload.');
      dispatch(endActivityHandler());
      count = 0;
      count15++;
      localStorage.removeItem('expireTime15');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  });

  return null;
};

export default InactivityLogoutTimer;
