import React, { useEffect, useState } from 'react';
import Homepage from '../pages/homepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Listing from '../pages/product/listing';
import PublicRoutes from './public-outlet';
import PrivateRoutes from './private-outlet';
import Profile from '../pages/profile';
import ProductDetail from '../pages/product-detail';
import Forgotpassword from '../pages/forgotpassword/index';
import OrderDetail from '../pages/order-detail';
import TermConditions from '../pages/terms-conditions';
import About from '../pages/about-us';
import Contact from '../pages/contact-us';
import Reviews from '../pages/reviews';
import FAQS from '../pages/qna';
import Privacy from '../pages/privacy-policy';
import CookieNotice from '../pages/cookie-notice';
import RefundPolicy from '../pages/refund-policy';
import ShippingReturnCancellationPolicy from '../pages/shipping-return-cancellation';
import VIP from '../pages/vip';
import SizeGuide from '../pages/size-guide';
import OrderTrackingGuide from '../pages/how-track-order';
import OrderGuide from '../pages/how-to-order';
import ReturnPolicy from '../pages/return-policy';
import AccountVerification from '../pages/account-verification';
import PageNotFound from '../pages/page-not-found';

import { useDispatch, useSelector } from 'react-redux';
import Cart from '../pages/product/cart';
import Wishlist from '../pages/wishlist';
import SubCategory from '../pages/sub-category';
import CreateOrder from '../pages/create-order';
import { v4 as uuidv4 } from 'uuid';
import { setGuestInfo } from '../redux/guestSlice';
import { fetchCartDetails } from '../redux/cartSlice';
import { fetchWishlistDetails } from '../redux/wishlistSlice';
import { fetchCountryList } from '../redux/homeSlice';
import Orderlist from '../pages/order-list';
import Account from '../pages/account';
import AccountRoutes from './account-outlet';
import ReturnOrder from '../pages/order-list/return-order';
import Settings from '../pages/settings';
import NotificationList from '../pages/notifications';
import RecentlyViewed from '../pages/recentlyViewed';
import { ScrollRestorationProvider } from '../context/scroll-context';
import OrderTrackingGuideMobile from '../pages/how-track-order/mobile';
import SubmitReview from '../pages/order-list/submitReview';
import InactivityLogoutTimer from '../components/inactivity-logout-timer';
import Platform from '../pages/platform';
import PlatformLogout from '../pages/platform-logout';
import { getCookie } from '../utils/functions';
import { fetchProfile } from '../redux/userSlice';
import isEmpty from 'is-empty';

const publicRouteLinks = [
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/product/listing',
    element: <Listing />,
  },
  {
    path: '/product/details/:id',
    element: <ProductDetail />,
  },
  {
    path: '/product/cart',
    element: <Cart />,
  },
  {
    path: '/product/category/:id/:name',
    element: <SubCategory />,
  },
  {
    path: '/wishlist',
    element: <Wishlist />,
  },
  {
    path: '/order-detail',
    element: <OrderDetail />,
  },
  {
    path: '/create/order',
    element: <CreateOrder />,
  },
  {
    path: '/forgot-password',
    element: <Forgotpassword />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermConditions />,
  },
  {
    path: '/about-us',
    element: <About />,
  },
  {
    path: '/contact-us',
    element: <Contact />,
  },
  {
    path: '/reviews',
    element: <Reviews />,
  },
  {
    path: '/qna',
    element: <FAQS />,
  },
  {
    path: '/privacy-policy',
    element: <Privacy />,
  },
  {
    path: '/cookie-notice',
    element: <CookieNotice />,
  },
  {
    path: '/refund-policy',
    element: <RefundPolicy />,
  },
  {
    path: '/shipping-return-cancellation-policy',
    element: <ShippingReturnCancellationPolicy />,
  },
  {
    path: '/vip',
    element: <VIP />,
  },
  {
    path: '/size-guide',
    element: <SizeGuide />,
  },
  {
    path: '/how-to-track-order',
    element: <OrderTrackingGuide />,
  },
  {
    path: '/how-to-track-order-mobile',
    element: <OrderTrackingGuideMobile />,
  },
  {
    path: '/how-to-order',
    element: <OrderGuide />,
  },
  {
    path: '/return-policy',
    element: <ReturnPolicy />,
  },
  {
    path: '/verify-account',
    element: <AccountVerification />,
  },
  {
    path: '/account',
    element: <Account />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
  {
    path: '/notifications',
    element: <NotificationList />,
    isAuth: true,
  },
  {
    path: '/my-orders',
    element: <Orderlist />,
    isAuth: true,
  },
  {
    path: '/order-detail/:id',
    element: <OrderDetail />,
    isAuth: true,
  },
  {
    path: '/profile',
    element: <Profile />,
    isAuth: true,
  },
  {
    path: '/return-order/:id',
    element: <ReturnOrder />,
    isAuth: true,
  },
  {
    path: '/submit-review/:id',
    element: <SubmitReview />,
    isAuth: true,
  },
  {
    path: '/recently-viewed',
    element: <RecentlyViewed />,
  },
  {
    path: '/platform',
    element: <Platform />,
  },
  {
    path: '/platform-logout',
    element: <PlatformLogout />,
  },
];

const RouteComponent = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  let auth = { token: localStorage.getItem('token') };

  useEffect(() => {
    dispatch(fetchCartDetails());
    dispatch(fetchWishlistDetails());
    dispatch(fetchCountryList());
  }, [dispatch]);

  useEffect(() => {
    const guestInfo = JSON.parse(localStorage.getItem('guestInfo'));

    if (!guestInfo?.id) {
      localStorage.setItem('guestInfo', JSON.stringify({ id: uuidv4() }));
    }
    dispatch(setGuestInfo(guestInfo || { id: uuidv4() }));
  }, [dispatch]);

  useEffect(() => {
    const token = getCookie('auth_token');

    if (token && token.length > 0) {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('token', token);
      dispatch(fetchProfile());
    }
  }, [dispatch]);

  return (
    <ScrollRestorationProvider>
      {' '}
      <Router>
        <Routes>
          {userData || auth.token ? (
            <>
              <Route element={<AccountRoutes />}>
                {/* <Route exact path={'/account'} element={<Account />} /> */}
              </Route>
              <Route element={<PrivateRoutes />}>
                {publicRouteLinks.map((route) => (
                  <Route
                    exact
                    path={route.path}
                    element={route.element}
                    key={route.path}
                  />
                ))}
                <Route path='*' element={<PageNotFound />} />
              </Route>
            </>
          ) : (
            <Route element={<PublicRoutes />}>
              {publicRouteLinks.map((route) => (
                <Route
                  exact
                  path={route.path}
                  element={route.element}
                  key={route.path}
                />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Route>
          )}
        </Routes>
      </Router>
    </ScrollRestorationProvider>
  );
};

export default RouteComponent;
