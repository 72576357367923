import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLogin, setRedirectionPath } from '../../redux/homeSlice';
import FormInput from '../../components/form-input';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { loginHandler } from '../../redux/authSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchProfile } from '../../redux/userSlice';
import { fetchCartList } from '../../redux/cartSlice';
import { FcGoogle } from 'react-icons/fc';

import {
  LoginSocialFacebook,
  IResolveParams,
  LoginSocialGoogle,
} from 'reactjs-social-login';

import {
  FacebookLoginButton,
  GoogleLoginButton,
} from 'react-social-login-buttons';
import { loginWithGoogle } from '../../services/auth-service';
import { useQuery } from '../../hooks/useQuery';
import { getCookie } from '../../utils/functions';

const LoginPage = ({ onClose, callBack, noSignup }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const redirectionPath = useSelector((state) => state.home.redirectionPath);

  const query = useQuery();
  const platform = getCookie('platform') === 'app';

  const [loading, setLoading] = useState(false);

  const handleForgotPassword = () => {
    onClose();
  };

  const onLoginStart = useCallback(() => {
    setLoading(true);
  }, []);

  const onLogoutSuccess = useCallback(() => {
    alert('logout success');
  }, []);

  const onLogout = useCallback(() => {}, []);

  const loginWithGoogleHandler = async (token) => {
    const paylaod = {
      token,
    };
    const res = await loginWithGoogle(paylaod);
    if (res.status === 200) {
      localStorage.clear();
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('token', res.data.data.token);
      toast({
        title: 'User logged-in Successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      if (redirectionPath) {
        navigate(redirectionPath);
        dispatch(setRedirectionPath(null));
      } else {
        navigate('/');
      }
      setLoading(false);
      console.log(redirectionPath, 'redirection : End');
      dispatch(fetchProfile());
      onClose();
    } else {
      toast({
        title: res?.response?.data?.message ?? 'Something went wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  console.log(platform, 'platform');

  return (
    <div className='login-container'>
      <div className='login-box'>
        <h2>Sign In</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required('Required'),
            password: Yup.string().required('Required'),
          })}
          onSubmit={(values) => {
            setLoading(true);
            dispatch(loginHandler(values)).then((res) => {
              setLoading(false);
              if (res.status === 200) {
                localStorage.clear();
                localStorage.setItem('isAuthenticated', 'true');
                localStorage.setItem('token', res.data.data.token);
                toast({
                  title: 'User logged-in Successfully',
                  status: 'success',
                  duration: 9000,
                  isClosable: true,
                });
                dispatch(fetchProfile());

                if (callBack) {
                  onClose();
                } else {
                  onClose();
                  console.log(redirectionPath, 'redirectionPath');

                  if (redirectionPath) {
                    //navigate('/account');
                    console.log('redirection end');
                    navigate(redirectionPath);
                  } else {
                    navigate('/');
                  }
                }
              } else {
                toast({
                  title: res?.response?.data?.message ?? 'Something went wrong',
                  status: 'error',
                  duration: 9000,
                  isClosable: true,
                });
              }
            });
          }}
          validateOnMount
        >
          {({ isValid }) => (
            <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
              <Flex flexDir={'column'} gap={'20px'}>
                <FormInput
                  name='email'
                  type='text'
                  label='Email Address'
                  placeholder='Email Address'
                />

                <FormInput
                  name='password'
                  type='password'
                  label='Password'
                  placeholder='Password'
                />
              </Flex>
              <Flex justify={'flex-end'}>
                <Text
                  onClick={handleForgotPassword}
                  textAlign='right'
                  marginTop='-15px'
                  color='#f41106'
                  width={'fit-content'}
                >
                  <Link to='/forgot-password'>Forgot Password?</Link>
                </Text>
              </Flex>

              <Button
                variant={'primary'}
                isDisabled={!isValid || loading}
                size={'sm'}
                type='submit'
                w={'200px'}
                // isLoading={isLoading}
                loadingText='Please wait...'
              >
                {loading ? <Spinner size='sm' /> : 'Sign in'}
              </Button>
            </Flex>
          )}
        </Formik>

        <Flex flexDir={'column'} gap={'10px'} mt='10px'>
          {/* <LoginSocialFacebook
            appId={process.env.REACT_APP_FB_APP_ID || ''}
            fieldsProfile={
              'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
            }
            onLoginStart={onLoginStart}
            onLogoutSuccess={onLogoutSuccess}
            redirect_uri={() => navigate('/')}
            onResolve={({ provider, data }) => {
              console.log(provider, data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <FacebookLoginButton />
          </LoginSocialFacebook> */}
          {platform ? (
            <Button
              onClick={() => navigate('/platform')}
              className='login-google-cta'
            >
              <Flex gap={'10px'} align={'center'} justify={'flex-start'}>
                <FcGoogle size={'30px'} />
                Login with Google
              </Flex>
            </Button>
          ) : (
            <LoginSocialGoogle
              isOnlyGetToken
              client_id={process.env.REACT_APP_GG_APP_ID || ''}
              scope='openid profile email'
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }) => {
                console.log(provider, data);
                loginWithGoogleHandler(data.access_token);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle>
          )}
          <Button>{platform}</Button>
        </Flex>
        {!noSignup && (
          <div className='signup-link'>
            <Flex justify={'center'} align={'center'} gap={'7px'}>
              Don't have an account?{' '}
              <Text
                cursor={'pointer'}
                onClick={() => {
                  dispatch(setIsLogin(false));
                }}
              >
                Sign Up
              </Text>
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
