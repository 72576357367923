import { useEffect, useRef, useState } from 'react';

export function useSwiperAutoplayFix(slides) {
  const swiperRef = useRef();
  const [autoplay, setAutoplay] = useState(false);
  const shouldAutoplay = slides.length > 1;

  if (autoplay !== shouldAutoplay) setAutoplay(shouldAutoplay);

  useEffect(() => {
    console.log(
      `Start autoplay if needed [has swiper: ${
        swiperRef.current != null
      }, start: ${autoplay}]`
    );
    if (autoplay) {
      const timer = setTimeout(() => {
        console.log('Calling swiper.autoplay.start');
        swiperRef.current?.autoplay.start();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [autoplay]);

  return swiperRef;
}
