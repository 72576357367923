import {
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../product-card';
import {
  getCategoryRecommendations,
  setCategoryRecommendations,
  setPage,
  setProductCard,
} from '../../redux/homeSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useParams } from 'react-router-dom';
import { setCategoryFirstLoad } from '../../redux/productSlice';

let count = 0;

const RecommendationCategory = ({
  fromHome,
  onCartOpen,
  fromListing,
  preventApiCall,
}) => {
  const {
    products,
    categoryRecommendations,
    hasMore,
    totalResults,
    page,
    isCategoryProductLoading,
  } = useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { filters, categoryFirstLoad } = useSelector((state) => state.product);
  const [isSmallerThan560] = useMediaQuery('(max-width: 560px)');
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan960] = useMediaQuery('(max-width: 960px)');
  const [isSmallerThan1185] = useMediaQuery('(max-width: 1185px)');
  const [isSmallerThan1430] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1530] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1800] = useMediaQuery('(max-width: 1800px)');
  const [isSmallerThan1920] = useMediaQuery('(max-width: 1920px)');
  const dispatch = useDispatch();
  const location = useLocation();
  const [scrollCount, setScrollCount] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { id } = useParams();
  const UserID = userData?.id ? userData.id : guestInfo.id;
  const searchParams = new URLSearchParams(location.search);
  const product_count = searchParams.get('product_count') || '0';

  const fetchData = useCallback(() => {
    dispatch(setCategoryRecommendations([]));
    const user_id = userData?.id || guestInfo?.id;
    const params = {
      categoryId: id,
      page_no: 1,
      product_count: product_count,
    };
    if (user_id) {
      dispatch(getCategoryRecommendations({ user_id, ...params }));
    }
    setIsFetching(false);
    dispatch(setCategoryFirstLoad(false));
    count++;
  }, [
    id,
    userData?.id,
    guestInfo?.id,
    filters,
    page,
  ]);

  const fetchMoreData = () => {
    if (
      categoryFirstLoad || 
      isFetching || !hasMore) return;
    const nextPage = page + 1;
    dispatch(setPage(nextPage));
    setIsFetching(true);

    const params = {
      user_id: UserID,
      categoryId: id,
      page_no: nextPage,
      product_count: product_count,
    };
    if (userData?.id || guestInfo?.id) {
      dispatch(getCategoryRecommendations(params, 'more')).then((res) => {
        if (res.status === 200) {
          setScrollCount((prev) => prev + 1);
          if (scrollCount + 1 == 4) {
            setShowMoreButton(true);
          }
        }
        setIsFetching(false);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [])

  const handleShowMore = () => {
    setScrollCount(0);
    setShowMoreButton(false);
    fetchMoreData();
  };

  const slidesPerView = location.pathname.includes('/product/listing')
    ? isSmallerThan560
      ? 2
      : 4
    : isSmallerThan560
      ? 2
      : isSmallerThan767
        ? 2
        : isSmallerThan960
          ? 2
          : isSmallerThan1185
            ? 3
            : isSmallerThan1430
              ? 4
              : isSmallerThan1530
                ? 5
                : isSmallerThan1800
                  ? 6
                  : isSmallerThan1920
                    ? 6
                    : 6;

  useEffect(() => {
    return () => {
      dispatch(setPage(1));
      dispatch(setCategoryFirstLoad(false));
    };
  }, [dispatch]);

  return (
    <>
      {!isCategoryProductLoading && totalResults === 0 && categoryRecommendations?.length === 0 && (
        <Text fontSize={'18px'} p='5' textAlign={'center'}>
          No Product found!
        </Text>
      )}

      {isCategoryProductLoading
        && categoryRecommendations?.length === 0
        ? (
          <Flex
            justify={'center'}
            align={'center'}
            padding={{ base: '10px 10px 50px 10px', md: '25px' }}
          >
            <Spinner />
          </Flex>
        ) : (
          <InfiniteScroll
            dataLength={categoryRecommendations?.length}
            next={scrollCount < 4 ? fetchMoreData : () => {}}
            hasMore={scrollCount < 4 && categoryRecommendations?.length < totalResults}
            loader={
              totalResults !== 0 &&
              scrollCount < 4 &&
              isFetching && (
                <Flex
                  justify={'center'}
                  align={'center'}
                  padding={{ base: '10px 10px 50px 10px', md: '25px' }}
                >
                  <Spinner />
                </Flex>
              )
            }
          >
            <SimpleGrid
              columns={slidesPerView}
              gridTemplateColumns={`repeat(${slidesPerView}, minmax(${isSmallerThan767 ? '145px' : '160px'
                }, 1fr))`}
              spacing={'13px'}
              mb={{ base: '50px', md: '20px' }}
            >
              {categoryRecommendations?.map((item, index) => (
                <ProductCard
                  onCartOpen={() =>
                    fromListing ? onCartOpen(item) : onCartOpen(item)
                  }
                  item={item}
                  onMouseEnter={() => {
                    const map = products.map((productItem, itemIndex) => ({
                      ...productItem,
                      showIcons: index === itemIndex,
                    }));
                    dispatch(setProductCard(map));
                  }}
                  onMouseLeave={() => {
                    const map = products.map((productItem) => ({
                      ...productItem,
                      showIcons: false,
                    }));
                    dispatch(setProductCard(map));
                  }}
                  fromListing
                />
              ))}
            </SimpleGrid>
          </InfiniteScroll>
        )}
      {showMoreButton && (
        <Flex justify={'center'}>
          <Button
            cursor='pointer'
            fontSize={14}
            fontWeight={600}
            textAlign='center'
            bg='black'
            color='white'
            onClick={handleShowMore}
            colorScheme='teal'
            m={{ base: '10px 10px 50px 10px', md: '20px' }}
            mx='auto'
            display='block'
            border='none'
            mb={{ base: '50px', md: '20px' }}
          >
            View More
          </Button>
        </Flex>
      )}
    </>
  );
};

export default RecommendationCategory;
