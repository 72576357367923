import { createSlice } from '@reduxjs/toolkit';
import {
  getProductPriceBySku,
  getSearchSuggestion,
  getSubCategories,
  productDetails,
  getShippingList,
  recentlyViewed,
  reportProduct,
  getReportCategory,
  productSummaryData,
  productFromSameSeller,
  getProductDetails,
} from '../services/product-service';
import isEmpty from 'is-empty';
import { getProducts } from '../services/home-service';
import { setStore } from './homeSlice';

const initialState = {
  isLoading: false,
  isProductLoading: false,
  productDetails: {
    images: [],
    thumb_images: [],
  },
  productFromSameSeller: [],
  isLogin: true,
  cartList: [],
  subCategoriesList: [],
  selectedCategory: {},
  suggestions: [],
  recentlyViewedList: [],
  shipment: [],
  productId: null,
  sku_id: null,
  selectedShipment: null,
  reportCategores: [],
  filters: {
    isFreeShipping: false,
    sale: false,
    min: null,
    max: null,
    rating: null,
    best_match: 1,
  },
  skuProperties: null,
  allProductLoaded: false,
  products: [],
  page: 1,
  totalCount: 0,
  isListingLoading: false,
  mobileFilters: {
    isFreeShipping: false,
    sale: false,
    min: null,
    max: null,
    rating: null,
    best_match: 1,
  },
  finalLoading: false,
  firstLoad: false,
  categoryFirstLoad: false,
  loaded: false,
  productDetailsLoaded: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsProductLoading(state, action) {
      state.isProductLoading = action.payload;
    },
    setProductDetails(state, action) {
      state.productDetails = action.payload;
    },
    setProductFromSameSeller(state, action) {
      state.productFromSameSeller = action.payload;
    },
    setCartList(state, action) {
      state.cartList = action.payload;
    },
    setSubCategoriesList(state, action) {
      state.subCategoriesList = action.payload;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setSuggestions(state, action) {
      state.suggestions = action.payload;
    },
    setRecentlyViewedList(state, action) {
      state.recentlyViewedList = action.payload;
    },

    setShipment(state, action) {
      // Add this action
      state.shipment = action.payload;
    },
    setProductId(state, action) {
      state.productId = action.payload;
    },
    setProductSkuId(state, action) {
      state.sku_id = action.payload;
    },
    setSelectedShipment(state, action) {
      state.selectedShipment = action.payload;
    },
    setReportCategories(state, action) {
      state.reportCategores = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setSkuProperties(state, action) {
      state.skuProperties = action.payload;
    },
    setAllProductLoaded(state, action) {
      state.allProductLoaded = action.payload;
    },
    setProducts(state, action) {
      state.products = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setTotalCount(state, action) {
      state.totalCount = action.payload;
    },
    setIsListingLoading(state, action) {
      state.isListingLoading = action.payload;
    },
    setMobileFilters(state, action) {
      state.mobileFilters = { ...state.mobileFilters, ...action.payload };
    },
    setFinalLoading(state, action) {
      state.finalLoading = action.payload;
    },
    setFirstLoad(state, action) {
      state.firstLoad = action.payload;
    },
    setCategoryFirstLoad(state, action) {
      state.categoryFirstLoad = action.payload;
    },
    setLoaded(state, action) {
      state.loaded = action.payload;
    },
    setProductDetailsLoaded(state, action) {
      state.productDetailsLoaded = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setIsProductLoading,
  setProductDetails,
  setProductFromSameSeller,
  setCartList,
  setSubCategoriesList,
  setSelectedCategory,
  setSuggestions,
  setRecentlyViewedList,
  setShipment,
  setProductId,
  setProductSkuId,
  setSelectedShipment,
  setReportCategories,
  setFilters,
  setSkuProperties,
  setFinalSkuId,
  setAllProductLoaded,
  setProducts,
  setPage,
  setTotalCount,
  setIsListingLoading,
  setMobileFilters,
  setFinalLoading,
  setFirstLoad,
  setCategoryFirstLoad,
  setLoaded,
  setProductDetailsLoaded,
} = productSlice.actions;

export default productSlice.reducer;

export const fetchProductDetails =
  (productId, sku_id, userId) => async (dispatch, getState) => {
    try {
      dispatch(setFinalLoading(true));
      dispatch(setProductDetails({ images: [], thumb_images: [] }));

      const country = getState().home.country;
      const currency = getState().home.currency;
      const userData = getState().user.userData;
      const token = localStorage.getItem('token');

      const response = await productDetails(
        productId,
        country,
        currency,
        userId,
        sku_id,
        isEmpty(token) ? '1' : 0
      );
      if (response.status === 200) {
        dispatch(setProductDetails(response.data.data));
        const _skuProperties =
          response?.data?.data?.products?.default_sku_attribute?.map((j) => {
            return {
              sku_id: j.sku_id,
            };
          });
        let obj = {};
        _skuProperties.forEach((el, index) => {
          if (index === 0) {
            obj['sku_id'] = el.sku_id;
          } else {
            obj[`sku_id_${index + 1}`] = el.sku_id;
          }
        });

        dispatch(setSkuProperties(obj));
      }
      dispatch(setProductDetailsLoaded(true));
      dispatch(setFinalLoading(false));
      return { status: response.status };
    } catch (error) {
      dispatch(setIsProductLoading(false));
      dispatch(setFinalLoading(false));
      dispatch({ type: 'FETCH_PRODUCT_DETAILS_FAILURE', error: error.message });
      return { status: 404 };
    }
  };
export const fetchProductDefaultDetails =
  (productId, sku_id, userId) => async (dispatch, getState) => {
    try {
      dispatch(setProductDetails({ images: [], thumb_images: [] }));
      dispatch(setIsProductLoading(true));
      const country = getState().home.country;
      const currency = getState().home.currency;
      const userData = getState().user.userData;
      const token = localStorage.getItem('token');

      const response = await getProductDetails(
        productId,
        country,
        currency,
        userId,
        sku_id,
        isEmpty(token) ? '1' : 0
      );
      if (response.status === 200) {
        dispatch(setProductDetails(response.data.data));
        const _skuProperties =
          response?.data?.data?.products?.default_sku_attribute?.map((j) => {
            return {
              sku_id: j.sku_id,
            };
          });
        let obj = {};
        _skuProperties.forEach((el, index) => {
          if (index === 0) {
            obj['sku_id'] = el.sku_id;
          } else {
            obj[`sku_id_${index + 1}`] = el.sku_id;
          }
        });

        dispatch(setSkuProperties(obj));
      }
      dispatch(setIsProductLoading(false));
      return { status: response.status };
    } catch (error) {
      dispatch(setIsProductLoading(false));
      dispatch({ type: 'FETCH_PRODUCT_DETAILS_FAILURE', error: error.message });
      return { status: 404 };
    }
  };

export const fetchProductFromSameSeller =
  (
    productId,
    sku_id,
    userId,
    type = '',
    storeName = '',
    isStore,
    page_no,
    more
  ) =>
  async (dispatch, getState) => {
    try {
      if (!more) {
        dispatch(setFirstLoad(true));
      }
      dispatch(setIsListingLoading(true));
      const products = getState().product.products;
      const country = getState().home.country;
      const currency = getState().home.currency;
      const token = localStorage.getItem('token');
      // Construct the API payload
      const payload = isStore
        ? {
            id: null,
            country,
            currency,
            user_id: userId || null,
            page_no,
            sku: null,
            type: 'full',
            is_guest: isEmpty(token) ? '1' : '0',
            store_name: storeName,
            is_store: isStore ? 1 : 0,
            page_no,
            // ...(isStore && { is_store: 1 })
          }
        : {
            id: productId,
            country,
            currency,
            user_id: userId,
            sku: sku_id,
            type,
            is_guest: isEmpty(token) ? '1' : '0',
            page_no,
          };

      // Call the API with the constructed payload
      const response = await productFromSameSeller(
        payload.id,
        payload.country,
        payload.currency,
        payload.user_id,
        payload.sku,
        payload.type,
        payload.is_guest,
        payload.store_name,
        payload.is_store,
        payload.page_no
      );
      if (response?.response?.status !== 200) {
        dispatch(setTotalCount(0));
      }

      if (response.status === 200) {
        if (!more) {
          dispatch(setProducts(response.data.data));
        } else {
          dispatch(setProducts([...products, ...response.data.data]));
        }
        dispatch(setStore(response.data?.store_name));
        dispatch(setTotalCount(response.data?.total_count));
      }
      dispatch(setIsListingLoading(false));
      return { status: response.status };
    } catch (error) {
      console.error('API Error:', error);
      dispatch(setIsListingLoading(true));
      return { status: 404 };
    }
  };

export const fetchSubCategories = (catId) => async (dispatch, getState) => {
  try {
    dispatch(setSubCategoriesList([]));
    dispatch(setIsLoading(true));
    const country = getState().home.country;
    const currency = getState().home.currency;
    const response = await getSubCategories(catId, country, currency);
    if (response.status === 200) {
      dispatch(setSubCategoriesList(response.data.data));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return error;
  }
};
export const fetchSuggestions = (query) => async (dispatch, getState) => {
  try {
    dispatch(setSuggestions([]));
    const country = getState().home.country;
    const currency = getState().home.currency;
    const response = await getSearchSuggestion(query, country, currency);
    if (response.status === 200) {
      dispatch(setSuggestions(response.data.data));
    }
  } catch (error) {
    return error;
  }
};
export const fetchProductBySku =
  (id, name, data) => async (dispatch, getState) => {
    try {
      const productDetails = getState().product.productDetails;
      const country = getState().home.country;
      const currency = getState().home.currency;

      const payload = {
        country,
        currency,
        product_id: id,
      };

      let skuProperties = {};

      productDetails?.products?.default_sku_attribute?.forEach((el, index) => {
        if (index === 0) {
          payload.sku_id = el.sku_id;
          payload.property_value_id = el.property_value_id;
          skuProperties['sku_id'] = el.sku_id;
        } else {
          payload[`sku_id_${index + 1}`] = el.sku_id;
          payload[`property_value_id_${index + 1}`] = el.property_value_id;
          skuProperties[`sku_id_${index + 1}`] = el.sku_id;
        }
        payload[`value_${index + 1}`] = el.value;
      });

      dispatch(setSkuProperties(skuProperties));

      // productDetails?.sku_property?.forEach((el, index) => {
      //   console.log(el, data, 'check');
      //   // const selected = el.variant_value.find((item) => item.id === data.id);

      //   if (el.name === name) {
      //     payload[`value_${index + 1}`] = data.value;
      //     if (index === 0) {
      //       payload.sku_id = data.sku_id;
      //     } else {
      //       payload[`sku_id_${index + 1}`] = el.sku_id;
      //     }
      //   } else {
      //     payload[`value_${index + 1}`] = el.value;
      //     payload[`sku_id_${index + 1}`] = el.sku_id;
      //   }
      // });

      const response = await getProductPriceBySku(payload);

      if (response.status === 200) {
        const productDetails = getState().product.productDetails;

        dispatch(
          setProductDetails({
            ...productDetails,
            products: {
              ...productDetails.products,
              price: response.data.data.price,
              mrp: response.data.data.mrp,
              quantity: response.data.data.avail_quantity,
              sku_id: response.data.data.sku_id,
              discount: response.data.data.discount,
            },
          })
        );
      }
    } catch (error) {
      return error;
    }
  };

export const getProductImageByColor = (image) => async (dispatch, getState) => {
  try {
    const productDetails = getState().product.productDetails;
    const images = productDetails.images;
    const index = images.findIndex((x) => x.id === image.id);

    dispatch(
      setProductDetails({
        ...productDetails,
        imageIndex: index,
      })
    );
  } catch (error) {
    return error;
  }
};

export const fetchShipmentList = (payload) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(setProductDetailsLoaded(false));
    const userData = getState().user.userData;
    if (isEmpty(userData)) {
      payload.is_guest = 1;
    }
    const response = await getShippingList(payload);
    if (response.status === 200) {
      dispatch(setShipment(response.data.data.shipment));

      dispatch(setSelectedShipment(response.data.data.shipment[0]));
      dispatch(setProductDetailsLoaded(true));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return error;
  }
};

export const getRecentlyViewed = (user_id) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    const country = getState().home.country;
    const currency = getState().home.currency;
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const userID = userData?.id || guestInfo.id;
    const response = await recentlyViewed(userID, country, currency);
    if (response.status === 200) {
      dispatch(setRecentlyViewedList(response.data.data));
    }
    dispatch(setIsLoading(false));
  } catch (error) {
    dispatch(setIsLoading(false));
    return error;
  }
};

export const fetchReportCategores = (payload) => async (dispatch) => {
  try {
    const response = await getReportCategory();
    if (response.status === 200) {
      dispatch(setReportCategories(response.data.data));
    }
  } catch (error) {
    return error;
  }
};
export const reportProductHandler = (payload) => async (dispatch) => {
  try {
    return await reportProduct(payload);
  } catch (error) {
    return error;
  }
};

export const getDataProductSummary =
  (payload) => async (dispatch, getState) => {
    try {
      const country = getState().home.country;
      const currency = getState().home.currency;
      let data = {
        ...payload,
        country,
        currency,
      };
      return await productSummaryData(data);
    } catch (error) {
      return error;
    }
  };

export const fetchAllProducts =
  (params, more) => async (dispatch, getState) => {
    try {
      if (!more) {
        dispatch(setFirstLoad(true));
      }
      dispatch(setIsListingLoading(true));
      const products = getState().product.products;

      const country = getState().home.country;
      const currency = getState().home.currency;
      const userData = getState().user.userData;
      const guestInfo = getState().guest.guestInfo;
      const user_id = userData?.id || guestInfo?.id;
      const page = getState().product.page;

      const response = await getProducts(params, country, currency, user_id);

      if (response?.response?.status !== 200) {
        dispatch(setTotalCount(0));
      }

      if (response.status === 200) {
        if (!more) {
          dispatch(setProducts(response.data.data));
        } else {
          dispatch(setProducts([...products, ...response.data.data]));
        }

        dispatch(
          setTotalCount(
            response.data.product_count || response.data.total_count
          )
        );
        dispatch(setLoaded(true));
      }
      dispatch(setIsListingLoading(false));
      return response;
    } catch (error) {
      dispatch(setIsListingLoading(true));
      return error;
    }
  };
