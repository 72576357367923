import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { setPage } from '../../redux/homeSlice';
import { useDispatch } from 'react-redux';
import { usehashParam } from '../../hooks/useHashParam';
import { visitPageHandler } from '../../redux/userSlice';
const Banner = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  return (
    <Box className='home-banner'>
      <Swiper
        loop={true}
        allowSlidePrev={true}
        allowSlideNext={true}
        modules={[Autoplay, Navigation]}
        navigation
        slidesPerView={1}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Image
              src={
                isSmallerThan767
                  ? item.mobile_image != null && item.mobile_image != ''
                    ? item.mobile_image
                    : item.image
                  : item.image
              }
              height={isSmallerThan767 ? '130px' : '315px'}
              cursor={'pointer'}
              onClick={() => {
                dispatch(setPage(1));
                const catQuery = item.category_id
                  ? `&categoryId=${usehashParam(item.category_id)}`
                  : '';
                navigate(
                  `product/listing?page_no=1&min_discount=${usehashParam(
                    item.min_discount
                  )}&max_discount=${usehashParam(
                    item.max_discount
                  )}${catQuery}&is_sold=${usehashParam(
                    item.is_sold
                  )}&rating=${usehashParam(
                    item.rating
                  )}&is_free_shipping=${usehashParam(
                    item.is_free_shipping
                  )}&search_key=${item.search_key}&min_price=${usehashParam(
                    item?.min_price
                  )}&max_price=${usehashParam(
                    item?.max_price
                  )}&name=${encodeURIComponent(
                    item.title.replace(/ /g, '-')
                  )}&categoryId=${usehashParam(
                    item?.sub_to_subcategory_id
                  )}&banner=${index}&product_count=${item.product_count}`
                );
                dispatch(visitPageHandler('banner', item?.title));
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default Banner;
